<template>
  <div>
    <vx-card>
      <h4>SR UT Activity</h4>
      <vs-row vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"
          >
          <vs-dropdown class="dropp-down" vs-custom-content vs-trigger-click v-if="logged_in_team == 'Admin' || logged_in_team == 'GM'"
            >
            <a class="a-icon a-iconn" href.prevent @click="showTreeBox"
              >
              SR
              <i class="material-icons">expand_more</i
              ></a
            >
            <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown"
              >
              <treeselect
                v-model="selected_sr_ids"
                :multiple="true"
                :options="sr_tree_data"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                Status
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect
                  v-model="selectStatus"
                  :multiple="true"
                  :options="treeDataStatus"
                  placeholder="Status"
                />
              </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
           <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                Activity
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect
                  v-model="select_tree_activity"
                  :multiple="true"
                  :options="treeDataActivity"
                  placeholder="Activity"
                />
              </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <!-- <label style="margin-right: 2%">Date Range</label> -->
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 240px; margin-right: 2%"
          ></date-range-picker>
          <!-- :disabled="selectStatus.length > 0 || select_tree_activity.length > 0 || dateRange.startDate !='' && dateRange.endDate !=''" -->
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="1.8"
        >
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="search"
            @click="getAllSrTeamUtilization(1)"
          ></vs-button>
          &nbsp;
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshFilterData"
          ></vs-button>
           &nbsp;
          <vs-button
            v-if="logged_in_team == 'SR'"
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="add"
            @click="openAddActivityPopup()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in search_criteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <br/>
      <vs-table :data="all_sr_team_utilization" class="mt-3">
        <template slot="thead">
          <vs-th> SPOC Name </vs-th>
          <vs-th>Date</vs-th>
          <vs-th> Activity </vs-th>
          <vs-th> Start Time </vs-th>
          <vs-th> End Time </vs-th>
          <vs-th> Duration </vs-th>
          <vs-th> Image </vs-th>
          <vs-th> UT % Duration </vs-th>
          <vs-th> Status </vs-th>
          <vs-th v-if="logged_in_team=='Admin' || logged_in_team == 'GM'"> Actions </vs-th>
          <!-- <vs-th> % UT </vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].spoc_name">
              {{ data[indextr].spoc_name }}
            </vs-td>
            <vs-td :data="data[indextr].start_time">
              {{unixDateConvert(data[indextr].start_time) }}
            </vs-td>
            <vs-td :data="data[indextr].activity">
              {{ activity_obj[data[indextr].activity] }}
            </vs-td>
            <vs-td :data="data[indextr].start_time">
              {{ unixTimestampConvert(data[indextr].start_time) }}
            </vs-td>
            <vs-td :data="data[indextr].end_time">
              {{ unixTimestampConvert(data[indextr].end_time) }}
            </vs-td>
            <vs-td :data="data[indextr].duration">
              {{ data[indextr].duration }}
            </vs-td>
            <vs-td :data="data[indextr].file_name">
              <vs-button
                v-if="data[indextr].file_name != null"
                color="#0044ba"
                icon="remove_red_eye"
                @click="
                  getPreviewSrc(
                    data[indextr].id,
                    data[indextr].spoc_name,
                    data[indextr].start_time,
                    data[indextr].end_time,
                    data[indextr].activity
                  )
                "
              ></vs-button>
            </vs-td>
             <vs-td :data="data[indextr].talk_time_utilization">
              {{data[indextr].talk_time_utilization}}
            </vs-td>
            <vs-td :data="data[indextr].status">
              {{ data[indextr].status }}
            </vs-td>
            <vs-td v-if="data[indextr].status == 'Pending' && (logged_in_team == 'Admin' || logged_in_team == 'GM')">
                <vs-button
                  size="small"
                  color="success"
                  @click="changeStatus(tr, 'Approve')"
                >
                  Approve
                </vs-button>
                <vs-button
                  size="small"
                  style="margin-left: 2%"
                  color="danger"
                  @click="changeStatus(tr, 'Reject')"
                >
                  Reject
                </vs-button>
            </vs-td>
            <vs-td v-else-if="data[indextr].status != 'Pending' && logged_in_team == 'Admin' || logged_in_team == 'GM'">
                <vs-button
                  disabled
                  size="small"
                  color="success"
                  @click="changeStatus(tr, 'Approve')"
                >
                  Approve
                </vs-button>
                <vs-button
                  disabled
                  size="small"
                  style="margin-left: 2%"
                  color="danger"
                  @click="changeStatus(tr, 'Reject')"
                >
                  Reject
                </vs-button>
            </vs-td>
            <!-- <vs-td v-else>
            </vs-td> -->
            <!-- <vs-td :data="data[indextr].duration"> </vs-td> -->
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-2"
        :total="tablelinks"
        v-model="currentTablePage"
      ></vs-pagination>
    </vx-card>
    <vs-popup
      classContent="holamundo schedule_popup"
      :title="`${this.button_status}`"
      :active.sync="show_sr_popup"
    >
      <vs-row>
        <b> Are sure you want to {{ this.button_status }}  {{activity_obj[popup_activity]}} for this SPOC ? </b>
      </vs-row>
      <vs-table :data="popup_data" class="mt-3">
        <template slot="thead">
          <vs-th> SPOC Name </vs-th>
          <vs-th> Date </vs-th>
          <vs-th> Activity </vs-th>
          <vs-th> Duration </vs-th>
          <vs-th> Image </vs-th>
          <vs-th> Status </vs-th>
          <!-- <vs-th> % UT </vs-th> -->
          <!--R -->
          <!--R -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].spoc_name">
              {{ data[indextr].spoc_name }}
            </vs-td>
            <vs-td :data="data[indextr].start_time">
             {{ unixDateConvert(data[indextr].start_time) }}
            </vs-td>
            <vs-td :data="data[indextr].activity">
              {{ activity_obj[data[indextr].activity] }}
            </vs-td>

            <vs-td :data="data[indextr].duration">
              {{ data[indextr].duration }}
            </vs-td>
            <vs-td :data="data[indextr].file_name">
              <vs-button
                color="#0044ba"
                icon="remove_red_eye"
                @click="getPreviewSrc(
                    data[indextr].id,
                    data[indextr].spoc_name,
                    data[indextr].start_time,
                    data[indextr].end_time,
                    data[indextr].activity
                )"
              ></vs-button>
            </vs-td>

            <vs-td :data="data[indextr].status">
              {{ data[indextr].status }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].duration"> </vs-td> -->
          </vs-tr>
        </template>
      </vs-table>
      <br />
      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
        >
          <vs-button
            :color="getColor(button_status)"
            type="filled"
            @click="updateActivityStatus(popup_data[0].id, button_status)"
            >{{ this.button_status }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      classContent="holamundo schedule_popup"
      :title="`Add UT Activity : ${getCurrentDate()}`"
      :active.sync="popupActivo2"
    >
      <vs-row class="mt-4">
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <p class="schedule_title">Activity:</p>
        </vs-col>
        <vs-col
          vs-w="6.6"
          vs-justify="flex-start"
          vs-align="center"
        >
          <!-- <div > -->
            <v-select
              v-model="add_activity"
              :options="activity_option"
              class="activity_dropdown"
              placeholder="Select Activity"
              style="
                z-index: 5000;
                margin-top: -1%;
                width: 100%;
              "
            ></v-select>
          <!-- </div> -->
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 8%">
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <p class="schedule_title">Time:</p>
        </vs-col>
        <vs-col
          vs-w="3.8"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vue-timepicker
            :minute-range="[0, 6, [10, 30], 42, 50]"
            :minute-interval="10"
            placeholder="From Time"
            v-model="start_time"
            class="TimePicker"
            id="from-time"
            format="hh:mm"
            @change="autoCloseTimePicker(start_time,'from-time')"
            style="position: fixed;z-index: 1500;border: 1px solid #0044ba;border-radius: 3px;width: 153px;">
          </vue-timepicker>
        </vs-col>
        <vs-col
          vs-w="3.8"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vue-timepicker
            :minute-range="[0, 6, [10, 30], 42, 50]"
            :minute-interval="10"
            class="TimePicker"
            v-model="end_time"
            placeholder="To Time"
            id="to-time"
            format="hh:mm"
            @change="autoCloseTimePicker(end_time,'to-time')"
            style="position: fixed;z-index: 1500;border: 1px solid #0044ba;border-radius: 3px;width: 153px;">
          </vue-timepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 8%">
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <p class="schedule_title">Image Upload:</p>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-offset="0.3"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          style="border: 1px solid #0044bf; border-radius: 5px"
        >
          <!-- <vs-input color="success"  type="file"  style="width: 100%;" @change="imageChange"/> -->
          <input type="file" @change="imageChange"  accept="image/*"/>
        </vs-col>
        <vs-col
          vs-w="1.5"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-button
            v-if="previewImage != null"
            color="#0044ba"
            icon="remove_red_eye"
            @click="popupActivo3 = true"
          ></vs-button>
          <vs-button
            v-else
            disabled
            color="#0044ba"
            icon="remove_red_eye"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row v-if="add_activity == 'Gmeet' || add_activity == 'Physical meet'" style="margin-top: 8%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <p style="margin-top: 10%" class="schedule_title"> Search Candidate:</p>
        </vs-col>
        <vs-col
          vs-w="6.6"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <vs-input style="border: 1px solid #0044bf; border-radius: 5px; width:100%" label-placeholder="Can Id" v-model="can_id" />
        </vs-col>
        <vs-col 
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-end"
          vs-w="1.4"
        >
          <vs-button
            style="margin-left: -10px"
            color="dark"
            type="gradient"
            icon="search"
            @click="search()"
            title="search"
          ></vs-button>
        </vs-col>
        <!-- <vs-col 
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1.5"
        >
          <vs-button
            style="margin-top: 20%"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refresh()"
            title="Refresh"
          ></vs-button>
        </vs-col> -->
      </vs-row> 
      <br/>
        <vs-table v-if="add_activity == 'Gmeet' || add_activity == 'Physical meet'" :data="can_details">
        <template slot="thead">
          <vs-th> Name </vs-th>
          <vs-th> Level </vs-th>
          <vs-th> Engagement details: </vs-th>
          <!-- <vs-th> % UT </vs-th> -->
          <!--R -->
          <!--R -->
        </template>
         <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].person_name" >
              {{ data[indextr].person_name }}
            </vs-td>
            <vs-td :data="data[indextr].level">
              {{ data[indextr].level }}
            </vs-td>
            <vs-td :data="data[indextr].engagement_details">
              {{ data[indextr].engagement_details }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="display: flex; justify-content: flex-end; margin-top: 4%">
        <vs-button color="primary" @click="addData">Add</vs-button>
      </div>
      <vs-popup
        :title="'Image Preview'"
        style="z-index: 53002"
        fullscreen
       :active.sync="popupActivo3"
      >
        <!-- <vs-images hover="zoom">
          <vs-image :src="previewImage" />
        </vs-images> -->
        <div style="display: flex; justify-content: center">
          <img :src="previewImage" width="1000"  />
        </div>
      </vs-popup>
    </vs-popup>
    <vs-popup
      style="z-index: 53001"
      fullscreen
      :active.sync="table_preview_popup"
      :title="`${spoc_name} - ${preview_activity} - ${preview_start_time} - ${preview_end_time}`"
    >
      <div style="display: flex; justify-content: center">
        <img :src="table_preview_image" width="1000" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import constants from "../../../constants.json";
import DateRangePicker from "vue2-daterange-picker";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import vSelect from "vue-select";
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    VueTimepicker,
    DateRangePicker,
    "v-select": vSelect,
    Treeselect,
  },
  mounted() {
    this.logged_in_team = localStorage.getItem("team");
    console.log("this.logged_in_team", this.logged_in_team);
    if (this.logged_in_team == "Admin" || this.logged_in_team == "GM") {
      this.getSRSpoc();
      this.getAllSrTeamUtilization(1);
    }
    if (this.logged_in_team == "SR") {
      this.logged_in_spoc_id = localStorage.getItem("spoc_id")
      this.selected_sr_ids.push(this.logged_in_spoc_id);
      this.getAllSrTeamUtilization(1);
    }
    // this.getAllSrTeamUtilization(1);
  },
  watch: {
    select_tree_activity(){
      this.showSearch();
    },
    selectStatus(){
      this.showSearch();
    },
    selected_sr_ids(val){
      console.log(val);
      this.showSearch();
    },
    currentTablePage(val) {
      this.getAllSrTeamUtilization(val);
    },
    // selectStatus(val) {
    //   this.status_filter_data = [];
    //   if (val == "All") {
    //     this.treeDataStatus[0].children.forEach((raw) => {
    //       this.status_filter_data.push(raw.label);
    //     });
    //   } else {
    //     this.status_filter_data.push(val);
    //   }
    //   console.log(val);
    // },
    // select_tree_activity(val) {
    //   this.activity_filter_data = [];
    //   if (val == "All") {
    //     this.treeDataActivity[0].children.forEach((raw) => {
    //       this.activity_filter_data.push(raw.label);
    //     });
    //   } else {
    //     this.activity_filter_data.push(val);
    //   }
    //   console.log(val);
    // },
  },
  data() {
    return {
      sr_spocs: [],
      cities_list: [],
      search_criteria: [],
      selected_mwb_id: '',
      can_id: "",
      can_details: [],
      logged_in_spoc_id: "",
      button_status: "",
      show_sr_popup: false,
      popup_data: [],
      filtered_sr_utilization: [],
      selected_sr_ids: [],
      rawcities: [],
      logged_in_team: "",
      sr_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      addUtPopup: false,
      popupActivo: false,
      popupActivo2: false,
      popupActivo3: false,
      // disableSearchBtn:true,
      selectedFile: null,
      currentTablePage: 1,
      preview_activity: "",
      tablelinks: 0,
      all_sr_team_utilization: [],
      status_filter_data:[],
      activity_filter_data: [],
      table_preview_popup: false,
      table_preview_image: "",
      selectStatus: [],
      select_tree_activity: [],
      spoc_name: "",
      preview_end_time: "",
      preview_start_time: "",
      start_time: {
        hh: null,
        mm: null,
      },
      end_time: {
        hh: null,
        mm: null,
      },
      dateRange: {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
      },
      previewImage: null,
      file_type: null,
      picture: null,
      activity: "",
      add_activity: "",
      activity_option: [
        "Face to face Class",
        "Gmeet",
        "Physical meet",
        "Online class/ Orientation/Event panelists",
        "LMS Training",
        "F2F or Online Event",
      ],
      activity_obj: {
        face_to_face: "Face to face Class",
        g_meet: "Gmeet",
        physical_meet: "Physical meet",
        online_class: "Online class/ Orientation/Event panelists",
        lms_training: "LMS Training",
        f2f: 'F2F or Online Event',
      },
      send_backend_activity: {
        "All":"All",
        "Face to face Class": "face_to_face",
        "Gmeet": "g_meet",
        "Physical meet": "physical_meet",
        "Online class/ Orientation/Event panelists": "online_class",
        "LMS Training": "lms_training",
        'F2F or Online Event': "f2f",
      },
      treeDataActivity: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Face to face Class",
              label: "Face to face Class",
            },
            {
              id: "Gmeet",
              label: "Gmeet",
            },
             {
              id: "Physical meet",
              label: "Physical meet",
            },
            {
              id: "Online class/ Orientation/Event panelists",
              label: "Online Class",
            },
            {
              id: "LMS Training",
              label: "LMS Training",
            },
            {
              id: "F2F or Online Event",
              label: "F2F or Online Event",
            },
          ],
        },
      ],
      treeDataStatus: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Approved",
              label: "Approved",
            },
            {
              id: "Pending",
              label: "Pending",
            },
            {
              id: "Rejected",
              label: "Rejected",
            },
          ],
        },
      ],
      popup_activity:""
    };
  },
  methods: {
    autoCloseTimePicker(val,id){
      let hh = val.hh != null && val.hh != ''
      let mm = val.mm != null && val.mm != ''
      if (hh && mm) {
        var link = document.getElementById(id);
        link.click();
      }
    },
    openAddActivityPopup(){
      this.can_details = [];
      this.can_id = "";
      this.selectedFile = null;
      this.previewImage = null;
      this.file_type = null;
      this.add_activity = "";
      this.start_time = {
        hh: null,
        mm: null,
      },
      this.end_time = {
        hh: null,
        mm: null,
      },
      this.popupActivo2 = true;
    },

    showSearch(){
      this.search_criteria = [];
      this.search_criteria.push("divider");
      this.selected_sr_ids.forEach((sort) => {
        if (sort === "All") {
          this.sr_tree_data[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              this.search_criteria.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.sr_tree_data[0].children.forEach((child) => {
            if (child.id === sort) {
              child.children.forEach((acutal_spoc) => {
                this.search_criteria.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
              this.sr_tree_data[0].children.forEach((child) => {
                  child.children.forEach((acutal_spoc) => {
                    if(acutal_spoc.id == sort){
                      this.search_criteria.push(acutal_spoc.label);
                    }
                  });
              });
          }
        }
      }); 
      this.search_criteria.push("divider");
      this.selectStatus.forEach((sort) => {
        if (sort === "All") {
          this.treeDataStatus[0].children.forEach((child) => {
            this.search_criteria.push(child.label);
          });
        } else {
          this.search_criteria.push(sort);
        }
      });
      this.search_criteria.push("divider");
      this.select_tree_activity.forEach((sort) => {
        if (sort === "All") {
          this.treeDataActivity[0].children.forEach((child) => {
            this.search_criteria.push(child.label);
          });
        } else {
          this.search_criteria.push(sort);
        }
      });
    },
    // removeSearch(chip){
    //   console.log("removeSearch",chip);
    //   if(this.selected_sr_ids.indexOf('All') !== -1){
    //     this.selected_sr_ids = []; 
    //     this.sr_tree_data[0].children.forEach((child) => {
    //       child.children.forEach((child2) => {
    //         if (child2.label !== chip) {
    //           this.selected_sr_ids.push(child2.label);
    //         }
    //       });
    //     }); 
    //   } else {
    //     let city = "";
    //     this.sr_tree_data[0].children.forEach((child) => {
    //       child.children.forEach((child2) => {
    //         if (child2.label === chip) {
    //           city = child.label;
    //         }
    //       });
    //     });
    //     this.selected_sr_ids.splice(this.selected_sr_ids.indexOf(city), 1);
    //     this.sr_tree_data[0].children.forEach((child) => {
    //       if (child.label === city) {
    //         child.children.forEach((child2) => {
    //           if (child2.label !== chip) {
    //             this.selected_sr_ids = this.selected_sr_ids.reduce((unique, o) => {
    //               if (!unique.some((sort) => sort.name === o.name)) {
    //                 unique.push(o);
    //               }
    //               return unique;
    //             }, []);
    //             this.selected_sr_ids.push(child2.label);
    //           }
    //         });
    //       }
    //     });
    //   }
    // },
    search() {
      this.can_details = [];
      let obj = {
        identity: this.can_id,
      };
      if (obj.identity !== "") {
          this.$vs.loading();
        axios
          .get(`${constants.MILES_CM_BACK}getCandidateDetailsByIdentity`, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.can_details.push(response.data);
            console.log("getCandidateDetailsByIdentity",this.can_details );
            // this.selected_mwb_id = response.data.mwb.id;
            // this.can_name = response.data.mwb
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        this.$vs.notify({
          title: "Please Enter Identity",
          color: "danger",
        });
      }
    },
    getColor(status) {
      if (status == "Approve") {
        return "success";
      }
      if (status == "Reject") {
        return "danger";
      }
    },
    changeStatus(row_data, status) {
      // console.log("row_data", row_data);
      this.popup_data = [];
      this.popup_data.push(row_data);
      this.button_status = status;
      this.popup_activity = row_data.activity
      this.show_sr_popup = true;
    },
    updateActivityStatus(spoc_id, status) {
      // this.$vs.loading();
      let changed_status = "";
      if(status == 'Approve'){
        changed_status = "Approved"
      }
      if(status == 'Reject'){
        changed_status = "Rejected"
      }
      let obj = {
        id : spoc_id,
        status : changed_status
      }
      let url = `https://miles-sr.2x2.ninja/api/updateActivityStatus`;
      axios
        .put(url,obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("updateActivityStatus", response);
          if(response.data == 'success'){
            this.$vs.notify({
              title: "Status Updated Successfully",
              color: "success",
            });
          }
          if(response.data.status == 'error'){
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          }
          this.getAllSrTeamUtilization(1);
          this.show_sr_popup = false;
          // this.all_sr_team_utilization = response.data;
          // this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          // this.$vs.loading.close();
        });
    },
   
    showTreeBox() {
      return this.sr_tree_data;
    },
    getSRSpoc() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.rawBdeSR = response.data.spocs;
          console.log("response.data.spocs",response.data.spocs);
          this.sr_spocs = response.data.spocs;
          this.cities_list  = [...new Set(response.data.spocs.map((item) => item.city)), ];
          this.sr_tree_data[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.sr_tree_data[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.sr_tree_data.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.id,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.sr_tree_data[0].children = this.sortNestedArrayAlphabetically(
            this.sr_tree_data[0].children
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    //R
    getCurrentDate() {
      let date = new Date().toJSON();
      let currentDate = moment(date).format("DD-MMM-YYYY");
      return currentDate;
    },
    getPreviewImage(file) {
      console.log("image", file);
      this.popupActivo = true;
    },
    imageChange(event) {
      console.log("imageChange",event);
      let file = event.target.files[0];
      this.selectedFile = file;
      this.previewImage = URL.createObjectURL(file);
      this.file_type = event.target.files[0].type;
      // console.log("obj",this.previewImage, event.target.files[0].type)
    },
   
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("hh:mm");
      return dateString;
    },
    unixDateConvert(value) {
      let date_value = moment.unix(value).format("DD-MMM-YYYY");
      return date_value;
    },
    getPreviewSrc(id, spoc, start_time, end_time, activity) {
      this.spoc_name = spoc;
      this.preview_start_time = this.unixTimestampConvert(start_time);
      this.preview_activity = this.activity_obj[activity];
      this.preview_end_time = this.unixTimestampConvert(end_time);
      this.$vs.loading();
      axios
        .get(`https://miles-sr.2x2.ninja/api/viewSrTeamUtilization?id=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("imagesrc", response.data);
          this.table_preview_image = response.data;
          this.table_preview_popup = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    addData() {
      console.log("upload image dgdrt",this.send_backend_activity[this.add_activity], this.add_activity);
      if(this.selectedFile == null){
        this.$vs.notify({
          title: "Please Upload Image",
          color: "danger",
        });
        return;
      }
      let date = new Date().toJSON();
      let currentDate = moment(date).format("DD-MMM-YYYY");
      let activity_data = this.send_backend_activity[this.add_activity]
      let start_date_time = `${currentDate} ${this.start_time.hh}:${this.start_time.mm}`;
      let end_date_time = `${currentDate} ${this.end_time.hh}:${this.end_time.mm}`;
      let start_time_stamp = moment(
        start_date_time,
        "DD-MMM-YYYY hh:mm"
      ).format("X");
      let end_time_stamp = moment(end_date_time, "DD-MMM-YYYY hh:mm").format(
        "X"
      );
      if(start_time_stamp == end_time_stamp){
        this.$vs.notify({
          title: "Start time and End time should not be same",
          color: "warning",
        });
        return;
      }
      if(end_time_stamp  < start_time_stamp){
        this.$vs.notify({
          title: "Start time should be less than End time",
          color: "warning",
        });
        return;
      }
      let user_id = localStorage.getItem("spoc_id");
      
      let obj = {
        file_name: this.selectedFile.name,
        spoc_id: user_id,
        activity: activity_data,
        start_time: start_time_stamp,
        end_time: end_time_stamp,
      };
     
      if(this.add_activity == 'Gmeet' || this.add_activity == 'Physical meet'){
        console.log("this.add_activity",this.can_details);
        if(this.can_details.length == 0){
          this.$vs.notify({
            title: "Please enter Can Id",
            color: "danger",
          });
          return;
        }
        obj.mwb_id = this.can_details[0].id
      }
      axios
        .post("https://miles-sr.2x2.ninja/api/storeSrTeamUtilization", obj,{
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        }
        )
        .then((response) => {
          console.log("response", response);
          this.uploadFile(response.data);
        })
        .catch(function (error) {
          this.handleError(error);
          this.$vs.notify({
              title: error,
              color: "danger",
            });
        });
      this.popupActivo2 = false;
    },
    uploadFile(url) {
      // console.log("Put methods Data",url,this.file_type,this.selectedFile)
      var options = {
        headers: {
          "Content-Type": this.file_type,
        },
      };
      axios
        .put(url, this.selectedFile, options)
        .then((response) => {
          if (response.status == 200) {
            this.$vs.notify({
              title: "Data Added Successfully",
              color: "success",
            });
          }
          this.getAllSrTeamUtilization(1);
          
          this.refreshData();
        })
        .catch(function (error) {
          this.handleError(error);
          this.getAllSrTeamUtilization(1);
          // this.popupActivo2 = false;
          this.refreshData();
           this.$vs.notify({
              title: error,
              color: "danger",
            });
        });
    },
    refreshData() {
      this.add_activity = null;
      this.selectedFile = null;
      this.file_type = null;
      this.start_time = {
        hh: null,
        mm: null,
      };
      this.end_time = {
        hh: null,
        mm: null,
      };
    },
    getDate(date) {
      let formatDate = moment(date).format("DD-MM-YYYY");
      return formatDate;
    },
    getAllSrTeamUtilization(page) {
      console.log("this.dateRange",this.dateRange);
      let obj_activity = [];
      let end_date ='';
      let start_date='';
      if (
        this.getDate(this.dateRange.startDate) != "Invalid date" &&
        this.getDate(this.dateRange.endDate) != "Invalid date"
      ) {
        // if(this.getDate(this.dateRange.startDate) == this.getDate(this.dateRange.endDate)){
        //   this.$vs.notify({
        //     title: "Start and End date should not be same",
        //     color: "warning",
        //   });
        //   return;
        // }
        start_date = this.getDate(this.dateRange.startDate);
        end_date = this.getDate(this.dateRange.endDate);
      }
      this.select_tree_activity.forEach((item) => {
        obj_activity.push(this.send_backend_activity[item]);
      });
      // this.status_filter_data.forEach((status) => {
      //   status_data.push(status);
      // });

      if(this.cities_list.includes(this.selected_sr_ids[0])){
        let city = this.selected_sr_ids[0];
        this.selected_sr_ids = [];
        for(let i = 0; i < this.sr_spocs.length; i++){
          const element = this.sr_spocs[i];
          if(element.city == city){
            this.selected_sr_ids.push(element.id);
          }
        }
      }
      let obj = {
        from_date: start_date,
        to_date: end_date,
        status: this.selectStatus.join(),
        activity: obj_activity.join(),
        spoc_ids: this.selected_sr_ids.join()
      };
      // console.log("page", page, end_date, start_date, obj, this.status_filter_data.length);
      this.$vs.loading();
      axios
        .get(
          `https://miles-sr.2x2.ninja/api/getAllSrTeamUtilization?page=${page}`,
          {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          this.tablelinks = response.data.last_page;
          this.all_sr_team_utilization = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    refreshFilterData(){
       this.tablelinks = 0;
       this.all_sr_team_utilization = [];
       this.selectStatus =[];
       this.select_tree_activity = [];
       this.selected_sr_ids= [];
       this.dateRange = {
        startDate: "",
        endDate: "",
      }
      if (this.logged_in_team == "Admin" || this.logged_in_team == "GM") {
      this.getSRSpoc();
      this.getAllSrTeamUtilization(1);
    }
    if (this.logged_in_team == "SR") {
      this.logged_in_spoc_id = localStorage.getItem("spoc_id")
      this.selected_sr_ids.push(this.logged_in_spoc_id);
      this.getAllSrTeamUtilization(1);
    }
    },
    // disableSearchBtn(){
    //   // var data_bulean = true;
    //   // if(this.selectStatus.length > 0 || this.select_tree_activity.length > 0 || this.dateRange.startDate != '' && this.dateRange.endDate !=''){
    //   //   data_bulean = true;
        
    //   // }else{
    //   //   data_bulean = false
    //   // }
    //   // console.log("boolean", data_bulean)
    //   return true;
    // }
  },
};
</script>

<style>
.TimePicker .display-time {
  border: 1px solid #fff;
  width: 150px !important;
  height: 37px !important;
  padding: 0.3em 0.5em;
  font-size: 1em;
}
/* .preview_popup .vs-popup--header {
  display: none;
} */
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
.vs__dropdown-menu {
  color: black;
}
.vs__dropdown--menu {
  width: 300px;
}
.a-iconn {
  padding: 0 10px 0 10px;
  height:36px;
  outline: none;
  text-decoration: none !important;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #bfbfbf
}
.dropp-down{
  width: 100%
}
.a-iconn > .material-icons{
  margin-left: auto;
  color:#bfbfbf
}
.dropdown-cm{
  width:15%
}
</style>